import { MarketCountryCode } from '@backmarket/http-api'
import {
  PaymentArchitecture,
  PaymentError,
  PaymentGroup,
  PaymentMethodCode,
  type PaymentMethodConfig,
  PaymentNetwork,
} from '@backmarket/http-api/src/api-specs-payment/payment'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

import translations from './methods.translations'
import type {
  PaymentGroupDynamicHardcodedConfigOptions,
  PaymentGroupHardcodedConfig,
  PaymentMethodHardcodedConfig,
  PaymentMethodWithInstallmentHardcodedConfig,
  PaymentNetworkHardcodedConfig,
} from './methods.type'

export const PAYMENT_METHOD_CONFIGS: Record<
  PaymentMethodCode,
  PaymentMethodHardcodedConfig | PaymentMethodWithInstallmentHardcodedConfig
> = {
  [PaymentMethodCode.AFFIRM]: {
    name: translations.affirmMethodName,
  },
  [PaymentMethodCode.AFTERPAY]: {
    name: translations.afterpayMethodName,
  },
  [PaymentMethodCode.APPLE_PAY]: {
    name: translations.applePayMethodName,
    isAvailableInBrowser: (config: PaymentMethodConfig) => {
      return window.ApplePaySession && 'merchantIdentifier' in config
        ? ApplePaySession.canMakePaymentsWithActiveCard(
            config.merchantIdentifier,
          )
        : Promise.resolve(false)
    },
  },
  [PaymentMethodCode.CARD]: {
    name: translations.cardMethodName,
  },
  [PaymentMethodCode.CLEARPAY]: {
    name: translations.clearpayMethodName,
  },
  [PaymentMethodCode.EPS]: {
    name: translations.epsMethodName,
  },
  [PaymentMethodCode.GIROPAY]: {
    name: translations.giropayMethodName,
  },
  [PaymentMethodCode.GOOGLE_PAY]: {
    name: translations.googlePayMethodName,
  },
  [PaymentMethodCode.IDEAL]: {
    name: translations.idealMethodName,
  },
  [PaymentMethodCode.KLARNA_PAY_LATER]: {
    name: translations.klarnaPayLaterMethodName,
  },
  [PaymentMethodCode.KLARNA_SLICE_IT]: {
    name: translations.klarnaSliceItMethodName,
  },
  [PaymentMethodCode.KONBINI_GENERIC]: {
    name: translations.konbiniGenericMethodName,
  },
  [PaymentMethodCode.MBWAY]: {
    name: translations.mbwayMethodName,
  },
  [PaymentMethodCode.PAYPAL]: {
    name: translations.paypalMethodName,
  },
  [PaymentMethodCode.ONEY3X]: {
    name: translations.oneyMethodName,
    label: translations.oneyMethodLabel,
    installmentCount: 3,
    ratesByCountry: {
      [MarketCountryCode.FR]: '22,08%',
    },
  },
  [PaymentMethodCode.ONEY4X]: {
    name: translations.oneyMethodName,
    label: translations.oneyMethodLabel,
    installmentCount: 4,
    ratesByCountry: {
      [MarketCountryCode.FR]: '22,22%',
    },
  },
  [PaymentMethodCode.ONEY6X]: {
    name: translations.oneyMethodName,
    label: translations.oneyMethodLabel,
    installmentCount: 6,
  },
  [PaymentMethodCode.ONEY10X]: {
    name: translations.oneyMethodName,
    label: translations.oneyMethodLabel,
    installmentCount: 10,
  },
  [PaymentMethodCode.ONEY12X]: {
    name: translations.oneyMethodName,
    label: translations.oneyMethodLabel,
    installmentCount: 12,
  },
  [PaymentMethodCode.SOFORT]: {
    name: translations.sofortMethodName,
  },
  [PaymentMethodCode.TRUSTLY]: {
    name: translations.trustlyMethodName,
  },
  [PaymentMethodCode.PROCESSOUT_SANDBOX]: {
    name: translations.processoutSandboxMethodName,
  },
}

export const PAYMENT_GROUP_CONFIGS: Record<
  PaymentGroup,
  PaymentGroupHardcodedConfig
> = {
  [PaymentGroup.APPLE_PAY]: {
    label: translations.applePayGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [],
    providerName: 'Apple Pay',
    errorMessages: {
      [PaymentError.PRICE_INCOMPATIBLE]:
        translations.applePayErrorPriceIncompatible,
    },
  },

  [PaymentGroup.CARD]: {
    label: translations.cardGroupLabel,
    hasCustomIcon: false,
    external: false,
    messages: [],
    providerName: translations.cardGroupProviderName,
  },

  [PaymentGroup.CLEARPAY]: {
    label: translations.clearpayGroupLabel,
    hasCustomIcon: true,

    messages: [
      translations.bodyClearPay,
      translations.bodyRedirection,
      translations.bodySeeYouSoon,
    ],
    providerName: 'Clearpay',
    errorMessages: {
      [PaymentError.DISABLED]: translations.clearpayErrorDisabled,
      [PaymentError.PRICE_INCOMPATIBLE]: translations.clearpayPriceIncompatible,
    },
  },

  [PaymentGroup.IDEAL]: {
    label: translations.iDealGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'iDeal',
  },

  [PaymentGroup.PAYPAL]: {
    label: translations.paypalGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'PayPal',
    errorMessages: {
      [PaymentError.DISABLED]: translations.paypalErrorDisabled,
      [PaymentError.COUNTRY_DISABLED]: translations.paypalErrorCountryDisabled,
      [PaymentError.ONLY_MERCHANT_INCOMPATIBLE]:
        translations.paypalErrorOnlyMerchantIncompatible,
      [PaymentError.ONE_MERCHANT_INCOMPATIBLE]:
        translations.paypalErrorOneMerchantIncompatible,
      [PaymentError.MANY_MERCHANTS_INCOMPATIBLE]:
        translations.paypalErrorManyMerchantsIncompatible,
      [PaymentError.PRICE_INCOMPATIBLE]:
        translations.paypalErrorPriceIncompatible,
      [PaymentError.INSURANCE_INCOMPATIBLE]:
        translations.paypalErrorInsuranceIncompatible,
    },
  },

  [PaymentGroup.MBWAY]: {
    label: translations.mbwayGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [],
    providerName: 'MB WAY',
    errorMessages: {
      [PaymentError.DISABLED]: translations.mbwayErrorDisabled,
      [PaymentError.COUNTRY_DISABLED]: translations.mbwayErrorCountryDisabled,
      [PaymentError.ONLY_MERCHANT_INCOMPATIBLE]:
        translations.mbwayErrorOnlyMerchantIncompatible,
      [PaymentError.ONE_MERCHANT_INCOMPATIBLE]:
        translations.mbwayErrorOneMerchantIncompatible,
      [PaymentError.MANY_MERCHANTS_INCOMPATIBLE]:
        translations.mbwayErrorManyMerchantsIncompatible,
      [PaymentError.PRICE_INCOMPATIBLE]:
        translations.mbwayErrorPriceIncompatible,
      [PaymentError.INSURANCE_INCOMPATIBLE]:
        translations.mbwayErrorInsuranceIncompatible,
    },
  },

  [PaymentGroup.KLARNA_PAY_LATER]: {
    label: translations.klarnaPayLaterGroupLabel,
    hasCustomIcon: true,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Klarna',
    errorMessages: {
      [PaymentError.DISABLED]: translations.klarnaErrorDisabled,
      [PaymentError.COUNTRY_DISABLED]: translations.klarnaErrorCountryDisabled,
      [PaymentError.ONLY_MERCHANT_INCOMPATIBLE]:
        translations.klarnaErrorOnlyMerchantIncompatible,
      [PaymentError.ONE_MERCHANT_INCOMPATIBLE]:
        translations.klarnaErrorOneMerchantIncompatible,
      [PaymentError.MANY_MERCHANTS_INCOMPATIBLE]:
        translations.klarnaErrorManyMerchantsIncompatible,
      [PaymentError.PRICE_INCOMPATIBLE]:
        translations.klarnaErrorPriceIncompatible,
      [PaymentError.INSURANCE_INCOMPATIBLE]:
        translations.klarnaErrorInsuranceIncompatible,
    },
  },

  [PaymentGroup.KLARNA_SLICE_IT]: {
    label: translations.klarnaSliceItGroupLabel,
    hasCustomIcon: true,
    external: true,
    messages: [
      translations.bodyKlarnaSliceIt,
      translations.bodyRedirection,
      translations.bodySeeYouSoon,
    ],
    providerName: 'Klarna',
    errorMessages: {
      [PaymentError.DISABLED]: translations.klarnaErrorDisabled,
      [PaymentError.COUNTRY_DISABLED]: translations.klarnaErrorCountryDisabled,
      [PaymentError.ONLY_MERCHANT_INCOMPATIBLE]:
        translations.klarnaErrorOnlyMerchantIncompatible,
      [PaymentError.ONE_MERCHANT_INCOMPATIBLE]:
        translations.klarnaErrorOneMerchantIncompatible,
      [PaymentError.MANY_MERCHANTS_INCOMPATIBLE]:
        translations.klarnaErrorManyMerchantsIncompatible,
      [PaymentError.PRICE_INCOMPATIBLE]:
        translations.klarnaErrorPriceIncompatible,
      [PaymentError.INSURANCE_INCOMPATIBLE]:
        translations.klarnaErrorInsuranceIncompatible,
    },
  },

  [PaymentGroup.KONBINI]: {
    label: translations.konbiniGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [],
    providerName: 'Konbini',
    errorMessages: {
      [PaymentError.DISABLED]: translations.konbiniErrorDisabled,
      [PaymentError.PRICE_INCOMPATIBLE]:
        translations.konbiniErrorPriceIncompatible,
    },
  },

  [PaymentGroup.AFFIRM]: {
    label: translations.affirmGroupLabel,
    hasCustomIcon: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Affirm',
    errorMessages: {
      [PaymentError.DISABLED]: translations.affirmErrorDisabled,
      [PaymentError.COUNTRY_DISABLED]: translations.affirmErrorCountryDisabled,
      [PaymentError.ONLY_MERCHANT_INCOMPATIBLE]:
        translations.affirmErrorOnlyMerchantIncompatible,
      [PaymentError.ONE_MERCHANT_INCOMPATIBLE]:
        translations.affirmErrorOneMerchantIncompatible,
      [PaymentError.MANY_MERCHANTS_INCOMPATIBLE]:
        translations.affirmErrorManyMerchantsIncompatible,
      [PaymentError.PRICE_INCOMPATIBLE]:
        translations.affirmErrorPriceIncompatible,
      [PaymentError.INSURANCE_INCOMPATIBLE]:
        translations.affirmErrorInsuranceIncompatible,
    },
  },

  [PaymentGroup.AFTERPAY]: {
    label: translations.afterpayGroupLabel,
    hasCustomIcon: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Afterpay',
    errorMessages: {
      [PaymentError.DISABLED]: translations.afterpayErrorDisabled,
      [PaymentError.PRICE_INCOMPATIBLE]: translations.afterpayPriceIncompatible,
    },
  },

  [PaymentGroup.ONEY]: {
    label: translations.oneyGroupLabel,
    hasCustomIcon: true,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Oney',
    // TODO [PAYIN-1896] Move this business logic to the API
    legalNotices: {
      [MarketCountryCode.FR]: {
        definition: translations.oneyLegalNotice,
        links: {
          link: {
            definition: translations.oneyLegalNoticeLink,
            url: 'https://www.orias.fr/',
          },
          link2: {
            definition: translations.oneyLegalNoticeLink2,
            url: 'https://www.orias.fr/welcome',
          },
          link3: {
            definition: translations.oneyLegalNoticeLink3,
            url: 'https://www.oney.fr',
          },
        },
        values: {
          rate3x: '20,89%',
          rate4x: '21,01%',
          rate10x: '16,90%',
          rate12x: '16,90%',
        },
      },
      [MarketCountryCode.ES]: {
        definition: translations.oneyLegalNotice,
        links: {
          link: {
            definition: translations.oneyLegalNoticeLink,
            url: 'https://www.oney.es/aviso-legal/informacion-legal-3x4xoney',
          },
        },
        values: {
          rate3x: '43,78%',
          rate4x: '43,78%',
          rate6x: '43,47%',
          rate12x: '28,79%',
        },
      },
    },
    errorMessages: {
      [PaymentError.DISABLED]: translations.oneyErrorDisabled,
      [PaymentError.COUNTRY_DISABLED]: translations.oneyErrorCountryDisabled,
      [PaymentError.ONLY_MERCHANT_INCOMPATIBLE]:
        translations.oneyErrorOnlyMerchantIncompatible,
      [PaymentError.ONE_MERCHANT_INCOMPATIBLE]:
        translations.oneyErrorOneMerchantIncompatible,
      [PaymentError.MANY_MERCHANTS_INCOMPATIBLE]:
        translations.oneyErrorManyMerchantsIncompatible,
      [PaymentError.PRICE_INCOMPATIBLE]:
        translations.oneyErrorPriceIncompatible,
      [PaymentError.INSURANCE_INCOMPATIBLE]:
        translations.oneyErrorInsuranceIncompatible,
    },
  },

  [PaymentGroup.SOFORT]: {
    label: translations.sofortGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Sofort',
    errorMessages: {
      [PaymentError.PRICE_INCOMPATIBLE]:
        translations.sofortErrorPriceIncompatible,
    },
  },

  [PaymentGroup.GIROPAY]: {
    label: translations.giropayGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Giropay',
  },

  [PaymentGroup.GOOGLE_PAY]: {
    label: translations.googlePayMethodName,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Google Pay',
  },

  [PaymentGroup.EPS]: {
    label: translations.epsGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'EPS',
  },

  [PaymentGroup.TRUSTLY]: {
    label: translations.trustlyGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'Trustly',
  },

  [PaymentGroup.PROCESSOUT_SANDBOX]: {
    label: translations.processoutSandboxGroupLabel,
    hasCustomIcon: false,
    external: true,
    messages: [translations.bodyRedirection, translations.bodySeeYouSoon],
    providerName: 'ProcessOut',
  },
}

/**
 * Change the label configuration depending on the form version
 * TODO: [PAYIN-4199] Remove when the AB test is over
 */
const dynamicLabel =
  (group: PaymentGroup, vNextLabel: I18nDefinition) =>
  (options: PaymentGroupDynamicHardcodedConfigOptions) => {
    const config = PAYMENT_GROUP_CONFIGS[group]
    if (options.formVersion !== 'v1') {
      config.label = vNextLabel
    }

    return config
  }

export const PAYMENT_GROUP_DYNAMIC_CONFIGS: Partial<
  Record<
    PaymentGroup,
    (
      options: PaymentGroupDynamicHardcodedConfigOptions,
    ) => PaymentGroupHardcodedConfig
  >
> = {
  [PaymentGroup.AFTERPAY]: dynamicLabel(
    PaymentGroup.AFTERPAY,
    translations.afterpayGroupLabelVNext,
  ),
  [PaymentGroup.CLEARPAY]: dynamicLabel(
    PaymentGroup.CLEARPAY,
    translations.clearpayGroupLabelVNext,
  ),

  [PaymentGroup.PAYPAL]: (options) => {
    const config = dynamicLabel(
      PaymentGroup.PAYPAL,
      translations.paypalGroupLabelVNext,
    )(options)

    return options.methods.some(
      (m) => m.architecture === PaymentArchitecture.FINPAY,
    )
      ? { ...config, messages: [translations.bodyPaypal, ...config.messages] }
      : config
  },

  [PaymentGroup.CARD]: dynamicLabel(
    PaymentGroup.CARD,
    translations.cardGroupLabelVNext,
  ),
}

export const PAYMENT_NETWORK_CONFIGS: Record<
  PaymentNetwork,
  PaymentNetworkHardcodedConfig
> = {
  [PaymentNetwork.ACCEL]: {
    name: 'Accel',
  },
  [PaymentNetwork.APPLE_PAY]: {
    name: translations.applePayNetworkName,
  },
  [PaymentNetwork.CARTES_BANCAIRES]: {
    name: translations.cartesBancairesNetworkName,
  },
  [PaymentNetwork.CLEARPAY]: {
    name: translations.clearpayNetworkName,
  },
  [PaymentNetwork.VISA]: {
    name: translations.visaNetworkName,
  },
  [PaymentNetwork.MAESTRO]: {
    name: translations.maestroNetworkName,
  },
  [PaymentNetwork.MASTERCARD]: {
    name: translations.mastercardNetworkName,
  },
  [PaymentNetwork.AMERICAN_EXPRESS]: {
    name: translations.americanExpressNetworkName,
  },
  [PaymentNetwork.DINERS]: {
    name: 'Diners',
  },
  [PaymentNetwork.DISCOVER]: {
    name: translations.discoverNetworkName,
  },
  [PaymentNetwork.BANCONTACT]: {
    name: translations.bancontactNetworkName,
  },
  [PaymentNetwork.MBWAY]: {
    name: translations.mbwayNetworkName,
  },
  [PaymentNetwork.PAYPAL]: {
    name: translations.paypalNetworkName,
  },
  [PaymentNetwork.AFFIRM]: {
    name: translations.affirmNetworkName,
  },
  [PaymentNetwork.AFTERPAY]: {
    name: translations.afterpayNetworkName,
  },
  [PaymentNetwork.EPS]: {
    name: translations.epsNetworkName,
  },
  [PaymentNetwork.GIROPAY]: {
    name: translations.giropayNetworkName,
  },
  [PaymentNetwork.GOOGLE_PAY]: {
    name: translations.googlePayMethodName,
  },
  [PaymentNetwork.IDEAL]: {
    name: translations.idealNetworkName,
  },
  [PaymentNetwork.JCB]: {
    name: translations.jcbNetworkName,
  },
  [PaymentNetwork.KLARNA]: {
    name: translations.klarnaNetworkName,
  },
  [PaymentNetwork.ONEY]: {
    name: translations.oneyNetworkName,
  },
  [PaymentNetwork.SOFORT]: {
    name: translations.sofortNetworkName,
  },
  [PaymentNetwork.TRUSTLY]: {
    name: translations.trustlyNetworkName,
  },
  [PaymentNetwork.PROCESSOUT]: {
    name: translations.processoutNetworkName,
  },
  [PaymentNetwork.FAMILYMART]: {
    name: translations.familymartNetworkName,
  },
  [PaymentNetwork.LAWSON]: {
    name: translations.lawsonNetworkName,
  },
  [PaymentNetwork.MINISTOP]: {
    name: translations.ministopNetworkName,
  },
  [PaymentNetwork.NYCE]: {
    name: 'NYCE',
  },
  [PaymentNetwork.PAYEASY]: {
    name: translations.payeasyNetworkName,
  },
  [PaymentNetwork.SEICOMART]: {
    name: translations.seicomartNetworkName,
  },
  [PaymentNetwork.PULSE]: {
    name: 'pulse',
  },
}
